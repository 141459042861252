.bgImg {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;

  &.is-home {
    background-image: var(--bg-home);
    height: 100vh;
    color: white;
    @media screen and (min-width: 768px) {
      color: black;
    }
  }

  &.is-map {
    background-image: var(--bg-map);
    height: 40vh;
    @media screen and (min-width: 768px) {
      height: 100vh;
    }
  }

  @media screen and (min-width: 768px) {
    width: 50%;
    float: right;
    background-size: cover;
    margin: 0 auto;
  }
}