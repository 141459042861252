//styles normalizer
@import '~modern-normalize/modern-normalize.css';

//fonts
@font-face {
  font-family: "Stockholm Regular";
  src: url("../assets/font/StockholmType-Regular.woff") format("woff");
}
@font-face {
  font-family: "Stockholm Bold";
  src: url("../assets/font/StockholmType-Bold.woff") format("woff");
}
//audioplayer
@import "audioplayer";

//buttons
@import "buttons";

//keyframes
@import "keyframes";

//mapImg
@import "bgImg";

//transitions
@import "transitions";

//theme
@import "theme";

//pin
@import "pin";

html, body {
  font-size: 16px;
  margin: 0;
  font-family: "Stockholm Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100vh;
  width: 100%;
}

@media (max-width: 600px) {
  html {
    font-size: 12px;
  }
}

#root {
  height: 100%;
}

*,
*::before,
*::after{
  box-sizing:border-box;
}
a {
  text-decoration:none !important;
  color:inherit;
  cursor:pointer;
}
button {
  background-color:transparent;
  color:inherit;
  border-width:0;
  padding:0;
  cursor:pointer;
}
figure {
  margin:0;
}
input::-moz-focus-inner {
  border:0; padding:0; margin:0;
}
ul, ol, dd{
  margin:0; padding:0;
}
li{
  padding-left: 1rem;
  margin-left: 2.1rem;
}
h1, h2, h3, h4, h5, h6, p{
  margin:0;
}
h1, h2, h3, h4, h5, h6{
  font-family: var(--font-bold);
}
h1 {
  font-size: 1.7rem;
  line-height: 2.1rem;
  @media screen and (min-width: 340px){
    font-size: 2.4rem;
    line-height: 3rem;
  }
  @media screen and (min-width: 380px) {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }
}
p{
  font-size: 1.6rem;
  line-height: 2rem;
}
cite {
  font-style:normal;
}
fieldset{
  border-width:0; padding:0; margin:0;
}

.overflow-hidden {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0;
}

.lazy-load-image-background.blur {
  filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  filter: blur(0);
  transition: filter .3s;
}

.lazy-load-image-background.blur > img {
  opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  opacity: 1;
  transition: opacity .3s;
}

.Link {
  text-decoration: underline !important;
}

.Hide {
  display: none;
}

.Show {
  display: block;

  &.Overflow {
    height: 100%;
    overflow-y: scroll;
  }
}

#map {
  height: calc(100vh - 130px);
}

// .leaflet-control-container {
//   display: none;
// }

.leaflet-touch .leaflet-bar {
  border-width: 1px;
}

.leaflet-touch .leaflet-bar a {
  background: var(--color-orange);
}

.leaflet-top {
  z-index: 999 !important;
}

.leaflet-left {
  left: auto;
  right: 0;
}

.leaflet-control {
  margin-right: 10px;
}

.leaflet-bottom {
  display: none;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.leaflet-popup-content-wrapper {
  border-radius: 0;
}

.leaflet-popup-content {
  color: var(--color-red);
  font-family: var(--font-bold);
}

article p {
  padding: 1rem 0;
}
.bold {
  font-family: var(--font-bold);
}

.italic {
  font-style: italic;
}

.is-small {
  font-size: 12px;
}

.poem {
  padding: 1.5rem var(--spacing-borders);
  display: inline-block;
  font-style: italic;
}

.poem--title {
  font-family: var(--font-regular);
}

.isHidden {
  display: none;
  height: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-click {
  pointer-events: none !important;
}

.is-text-center {
  display: block;
  text-align: center;
}

.is-text-justify {
  text-align: justify;
}

.is-flex-center {
  display: flex;
  align-items: center;
}

.is-font-xs {
  font-size: 12px;
}

.is-button{
  opacity: 0;
  transform: scale(0.9);
}

.is-button.is-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 400ms, transform 400ms;
  transition-delay: 400ms;
}

.preloaderContainer {
  position: relative;
  background-color: white;
  height: 20vh;
  width: calc(100% - 4rem);
  margin: 0 auto;
}

.overlay {
  display: none;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, .7);

  &.isActive {
    display: block;
  }

  .innerText {
    width: max-content;
    width: -moz-max-content;
    max-width: calc(100% - 4rem);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: var(--color-red);
    font-size: 3rem;
    line-height: 3rem;
    padding: 1.5rem;
    display: inline;
    text-decoration: none;

    @media screen and (min-width: 768px) {
      max-width: 600px;
    }

    &.isLoading {
      height: 50vh;
      width: 100%;
    }

    @media screen and (min-width: 768px) {
      &.isDone {
        a {
          float: left;
        }
  
        .float-right {
          float: right;
        }
      }
    }
  
    h4 {
      font-family: var(--font-bold);
      padding-bottom: 1rem;
    }

    .innerTextSmall {
      font-size: 16px;
      line-height: 1.3em;

      ul {
        padding-top: .5rem;
      }

      li {
        line-height: normal;
        margin-bottom: 0;
      }
    }
  }
}

.Box--green {
  display: flex;
  background: var(--color-green-light);
  padding: 1.5rem 1rem;
  align-items: center;
  margin-top: 1rem;

  svg {
    height: 40px;
    width: auto;
    min-width: 40px;
    margin-right: 1rem;
  }
}

.is-indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
  
  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #01a4e9;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  
  &:after {
    content: '';
    position: absolute;
    left: 0; 
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    // background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0,0,0,.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

.is-instruction {
  // animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}

@keyframes shake-animation {
  0% { transform:translate(0,0) }
  1.78571% { transform:translate(5px,0) }
  3.57143% { transform:translate(0,0) }
  5.35714% { transform:translate(5px,0) }
  7.14286% { transform:translate(0,0) }
  8.92857% { transform:translate(5px,0) }
  10.71429% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
}

@keyframes pulse-dot {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}