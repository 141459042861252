.btn {
  font-family: var(--font-bold);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
	color: #fff;
	transition: .4s ease;
	margin-top: 2rem;
	height: 50px;
	font-size: 2rem;
  cursor: pointer;
  border: 2px solid;
  font-size: 2rem;
  line-height: 2.4rem;

  &.is-blue {
    background-color: var(--color-blue);
    border-color: var(--color-blue);
    &:hover {
      color: var(--color-blue);
    }
  }

  &.is-red {
    background-color: var(--color-red);
    border-color: var(--color-red);
    &:hover {
      color: var(--color-red);
    }
  }

  &.is-green {
    background-color: var(--color-green);
    border-color: var(--color-green);
    &:hover {
      color: var(--color-green);
    }
  }

  &.is-purple {
    background-color: var(--color-purple);
    border-color: var(--color-purple);
    &:hover {
      color: var(--color-purple);
    }
  }

  &:hover {
    background-color: transparent;
  }

  &.is-white {
    background-color: white;
    color: var(--color-red);
    border-color: white;

    @media screen and (min-width: 768px) {
      border-color: var(--color-red);
    }

    &:hover {
      color: white;
      background-color: var(--color-red);
      border-color: var(--color-red);
    }
  }

  &.is-inactive {
    background-color: var(--color-inactive);
    border-color: var(--color-inactive);

    &:hover {
      color: white;
    }
  }

  @media screen and (min-width: 768px) {
    width: 270px;
  }
}