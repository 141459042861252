svg {
  height: 100%;
  width: 100%;
}

.cardWrapper {
  height: 100%;
}

.cardInner {
  background-color: var(--color-orange);
  padding: 0 var(--spacing-borders);
  height: 100%;
  overflow: hidden;
}

.cardHeading {
  background-color: var(--color-orange);
  height: 82px;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-borders);
}

@media screen and (min-width: 768px) {
  .image {
    max-width: 800px;
    display: block !important;
    margin: 0 auto;
  }
}

.cardBody {
  background-color: white;
  flex: 1 1 auto;
  padding: 0.5rem var(--spacing-borders) var(--spacing-borders);
  height: 100%;

  @media screen and (min-width: 768px) {
    max-width: 600px;
    margin: 0 auto;
  }
}

.image img {
  vertical-align: bottom;
}

.imgCaption,
.imgRef {
  font-size: 10px;
  line-height: 12px;
}

.imgRef {
  padding-top: 2rem;
}

.description {
  padding-top: 2rem;
}

.overlay {
  display: none;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, .3);

  &.isActive {
    display: block;
  }

  svg {
    background-color: white;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    position: absolute;
    right: var(--spacing-borders);
    top: var(--spacing-borders)
  }

  .innerText {
    width: max-content;
    width: -moz-max-content;
    max-width: 95vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: var(--color-red);
    font-size: 3rem;
    line-height: 3rem;
    padding: 1.5rem;
    display: inline;
    text-decoration: none;

    h4 {
      font-family: var(--font-bold);
      padding-bottom: 1rem;
    }

    .innerTextSmall {
      font-size: 16px;
      padding-top: .5rem;
      line-height: 1.3em;

      li {
        line-height: normal;
        margin-bottom: 0;
      }
    }
  }
}

.back {
  position: absolute;
  top: 0;
  z-index: 999;
  background-color: white;
  padding: .2rem;
  margin: 10px;
  color: var(--color-red)
}