.rhap_container {
  background-color: var(--color-orange-light);
  height: 120px;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
}

.rhap_main {
  height: 100%;
}

.rhap_progress-section {
  display: flex;
  justify-content: space-between;
  height: 4rem;
  width: 100%;
}

.rhap_time {
  font-size: 12px;
}

.rhap_progress-container {
  position: absolute;
  width: calc(100% - 2rem);
  top: 2.5rem;
  display: flex;
  align-items: center;
  height: 1rem;
  flex: 1 0 auto;
  align-self: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 4px;
  background-color: #dddddd;
}

.rhap_progress-bar-show-download {
  background-color: rgba(221, 221, 221, 0.5);
}

.rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 1rem;
  height: 1rem;
  top: -3px;
  background:var(--color-red);
  border-radius: 50px;
  //box-shadow: rgba(134 134 134 / 50%) 0 0 5px;
}

.rhap_progress-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: var(--color-red);
}

.rhap_download-progress {
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: #dddddd;
}

.rhap_main-controls {
  text-align: center;
  font-size: 3rem;
  width: 260px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.hap_main-controls-button {
  display: flex;
  align-items: center;

  svg {
    transition: .2s;
    transform: scale(1);

    &:active{
      transform: scale(.9);
    }
  }
}

.rhap_rewind-button,
.rhap_forward-button {
  height: 49px;
  width: 38px;
}

.rhap_play-pause-button {
  height: 49px;
  width: 45px;
  display: flex;
  align-items: center;
}

.rhap_additional-controls,
.rhap_volume-controls {
  display: none;
}

.playIcon svg,
.replayIcon svg {
  height: 23px;
  width: 23px;
}