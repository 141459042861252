.Pin--container {
  height: 47px;
  max-width: 30px;
  width: 100% !important;
  background-image: var(--bg-pin-red);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 1.8rem;
  z-index: 999 !important;

  &.on-map {
    cursor: pointer;
    width: 35px !important;
    height: 42px !important;
    transition: .4s ease;

    button {
      padding-top: .5rem;
      width: 100%;
    }
  }

  &.is-blue {
    background-image: var(--bg-pin-blue);
    z-index: 5 !important;
  }

  &.is-grey {
    background-image: var(--bg-pin-grey);
  }

  &.is-user {
    background-image: var(--bg-pin-user);
  }

  p,
  button {
    color: white;
    font-family: var(--font-bold);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem
  }
}