.layout {
  color: var(--txt);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.map {
  background-size: cover;
  height: 100%;
  width: 100%;
  position: fixed;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
}