:root {
  font-size: 10px;
  --bg-home: url(../assets/home-bg.png);
  --bg-pin-red: url(../assets/pin-red.svg);
  --bg-pin-blue: url(../assets/pin-blue.svg);
  --bg-pin-grey: url(../assets/pin-grey.svg);
  --bg-pin-user: url(../assets/pin-user.svg);
  --color-red: rgb(221, 74, 44);
	--color-purple: rgb(118, 51, 140);
	--color-blue: rgb(0, 126, 199);
  --color-blue-light: rgb(166, 200, 236);
	--color-green: rgb(0, 134, 127);
  --color-green-light: #AAD8D5;
	--color-orange: #FDE5D7;
	--color-orange-light: #FEF2EB;
  --color-grey: #e2e2e2;
	--color-inactive: #BABABA;
  --spacing-borders: 2rem;
  --font-regular: "Stockholm Regular";
  --font-bold: "Stockholm Bold";
}

.has-orange-background {
  background-color: var(--color-orange);
}

.has-orange-light-background {
  background-color: var(--color-orange-light);
}

.has-green-light-background {
  background-color: var(--color-green-light);
}

.has-padding {
  padding: var(--spacing-borders);
}

.has-padding-borders {
  padding: 0 var(--spacing-borders);
}

.has-padding-top {
  padding-top: var(--spacing-borders);
}

.has-padding-bottom {
  padding-bottom: var(--spacing-borders);
}
