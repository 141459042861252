.toggleOuter {
  width: 100%;
  height: 5px;
  position: fixed;
  z-index: 9;
  background: var(--color-orange);
}

.toggle {
  background-color: var(--color-orange);
  position: absolute;
  width: 100px;
  height: 29px;
  right: var(--spacing-borders);
  top: -27px;
  padding: .5rem;
  text-align: center;
  font-size: 12px;
  border-radius: 10px 10px 0 0;

  @media screen and (min-width: 768px) {
    top: -29px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: var(--font-bold);
  }

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
}

.container {
  background-color: white;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  word-wrap: break-word;
  transition: all 0.5s ease 0s;
  color: var(--txt);
  padding-bottom: 120px;
  bottom: 0;
  height: 195px;

  &.isActive {
    height: 82vh;

    @media screen and (min-width: 768px) {
      height: 94vh;
    }

    .inner {
      height: 100%;
      overflow-y: scroll;
    }
  }
}

.modal {
  height: 100%;
}

.closeWrapper {
  position: relative;
  z-index: 150;
  width: 100%;
  height: 30px;
  margin-top: -30px;
  bottom: -30px;
  display: flex;
}

.closeIcon {
  color: var(--modal-close-icon);
  position: relative;
  right: -3px;
  top: -1px;
  stroke-width: 4px;
  transition: all 0.4s ease 0s;
  &:hover {
    transform: scale(1.1);
  }
}

.closeButton {
  margin-left: auto;
  right: 0;
  background-color: var(--modal-close);
  border-radius: 0 0 0 100%;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.08);
  width: 45px;
  height: 45px;
  padding: 0;
}

@media (max-height: 575px) {
  .body {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}